import React from 'react'
import { Link as GLink } from 'gatsby'
import Cookies from 'universal-cookie'
import { Button, Card as CardComponent, Box, Flex } from 'theme-ui'
import { Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Sticky from '@components/Sticky'
import Seo from '@widgets/Seo'
import AuthorCompact from '@widgets/AuthorCompact'
import Categories from '@widgets/Categories'
import Tags from '@widgets/Tags'
import {
  PostHead,
  PostImage,
  PostBody,
  PostComments,
  PostTagsShare,
  PostFooter,
} from '@widgets/Post'
import { useBlogCategories, useBlogTags } from '@helpers-blog'

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  }
}

const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts, previous, next },
  location,
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : []),
  ]
  const { pageContext: { services = {}, siteUrl } = {} } = props
  const categories = useBlogCategories()
  const tags = useBlogTags()

  if (typeof window !== 'undefined') {      
    const cookies = new Cookies();
    cookies.set('addlBrands', post.title)
  }
  
  return (
    <>
      <Seo {...post} title={`${post.title} Franchise Opportunity`} siteUrl={siteUrl} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PostHead {...post} />
      </Stack>
      <Divider />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper'>
            <PostImage {...post} inCard />
            <PostBody {...post} />
            <Button as={GLink} to='/contact/'>Request More Information</Button>
            <PostTagsShare {...post} location={location} />
            {services.disqus && <PostComments {...post} />}
            <PostFooter {...{ previous, next }} />
          </CardComponent>
        </Main>
        <Sidebar>
          <AuthorCompact author={post.author} omitTitle />
          <Divider />
          <Categories categories={categories} />
          <Divider />
          {post.category && (
            <Sticky>
              <CardList
                title='Related'
                nodes={relatedPosts}
                variant='horizontal-aside'
                omitMedia
                omitCategory
                omitAuthor
                limit={9}
                distinct
                aside
              />
            </Sticky>
          )}
        </Sidebar>
      </Stack>
      <Divider space={4} />
      <Stack effectProps={{ effect: false }}>
        <Flex sx={styles.grid}>
          <Box sx={styles.column}>
            <Categories categories={categories} variant='vertical' />
          </Box>
          <Box sx={styles.column}>
            <Tags tags={tags} />
          </Box>
        </Flex>
      </Stack>
    </>
  )
}

export default Post
